import { ReactComponent as Like } from 'src/app/asset/icons/like.svg';
import { ReactComponent as Share } from 'src/app/asset/icons/share.svg';
import { ReactComponent as Bedroom } from 'src/app/asset/icons/bedroom.svg';
import { ReactComponent as Location } from 'src/app/asset/icons/Location.svg';
import { ReactComponent as Bathroom } from 'src/app/asset/icons/bathroom.svg';
import './PropertyCard.css';
import { useNavigate } from 'react-router-dom';
import { Button, Carousel, message } from 'antd';
import { APP_URL } from 'src/app/lib/variables';
import { formatMoney, truncateWords } from 'src/app/core/utils/helpers';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { File } from 'rentfree-api';

interface PropertyCardProps
  extends React.BaseHTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  title: string;
  files: File[];
  description: string;
  price: number;
  location: string;
  url: string;
  bedrooms: number;
  bathrooms: number;
  createdAt?: string;
}

export default function PropertyCard(props: PropertyCardProps) {
  const {
    title,
    files,
    price,
    description,
    location,
    url,
    bedrooms,
    bathrooms,
    createdAt,
  } = props;
  const carouselRef = useRef<any>(null);
  const navigate = useNavigate();

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'check out this property',
          text: 'I found something amazing!',
          url: `${APP_URL}${url}`,
        });
      } catch (error) {
        message.error('Failed to share');
      }
    }
  };

  const images = [
    'https://place-hold.it/500x500',
    'https://place-hold.it/500x500',
    'https://place-hold.it/500x500',
  ];

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShare();
  };

  return (
    <div className='ApartmentCardContainer' onClick={() => navigate(url)}>
      <div className='carousel-wrapper'>
        <Button
          className='custom-arrow left-arrow'
          icon={<LeftOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            carouselRef.current?.prev();
          }}
        />

        {files.length > 0 ? (
          <Carousel ref={carouselRef}>
            {files.map((src, index) => (
              <div key={index} className='carousel-slide'>
                <img
                  src={src.url}
                  alt={`property ${index + 1}`}
                  className='carousel-image'
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel ref={carouselRef}>
            {images.map((src, index) => (
              <div key={index} className='carousel-slide'>
                <img src={src} alt={`property ${index + 1}`} className='carousel-image' />
              </div>
            ))}
          </Carousel>
        )}

        <Button
          className='custom-arrow right-arrow'
          icon={<RightOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            carouselRef.current?.next();
          }}
        />
      </div>

      <div className='carddetails'>
        <p className='time-posted text-small text-subdued'>{createdAt}</p>
        <p className='text-medium text-semibold'>{title}</p>

        <p className='text-blue text-semibold text-blue' style={{ marginTop: '9px' }}>
          {formatMoney(String(price))}
        </p>
        <div className='address'>
          <Location className='icon' />
          <p className='text-subdued text-small text-semibold'>{location}</p>
        </div>
        <p className='text-subdued text-small' style={{ marginTop: '9px' }}>
          <div dangerouslySetInnerHTML={{ __html: truncateWords(description) }} />
        </p>

        <div className='cardfooter'>
          <div className='properties'>
            <div className='sub-button'>
              <Bedroom />
              <p className='text-small text-subdued'>{bedrooms} bedrooms</p>
            </div>
            <div className='sub-button'>
              <Bathroom />
              <p className='text-small text-subdued'>{bathrooms} bathrooms</p>
            </div>
          </div>
          <div className='cardaction'>
            <Button type='link' className='sub-button' onClick={handleShareClick}>
              <Share />
              <p className='text-small text-subdued'>Share</p>
            </Button>
            <Button type='link' className='sub-button'>
              <Like />
              <p className='text-small text-subdued'>Like</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
