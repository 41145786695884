import { Avatar, Button, Card } from 'antd';
import './BookNow.css';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import {
  capitalizeFirstLetter,
  getDetailedRelativeTime,
} from 'src/app/core/utils/helpers';
import { Property } from 'rentfree-api';

interface Props {
  property: Property;
}

export default function BookNow({ property }: Props) {
  return (
    <div className='book-now-wrapper'>
      <Card className='book-now-container'>
        <div className='agent-details'>
          <Avatar
            className='shape-avatar'
            shape='circle'
            size={40}
            src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${property.user.id}`}
          ></Avatar>
          <div>
            Posted by{' '}
            <span>
              {property.user.firstName} {property.user.lastName}
            </span>
            <br />
            <span>{capitalizeFirstLetter(property.user.role)}</span> .{' '}
            {getDetailedRelativeTime(property.createdAt!)}
          </div>
        </div>

        <div className='view-more'>
          <Link to='#'>View agent properties</Link>
          <ArrowRightOutlined />
        </div>

        <div className='book-now-actions'>
          <Button> Call Agent</Button>
          <Button type='primary'> Email Agent</Button>
        </div>
      </Card>

      <Card className='ads'>
        <p>Ads placement</p>
      </Card>
    </div>
  );
}
