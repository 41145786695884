import { Col, Switch, Form } from 'antd';

const PropertyFeatures = () => {
  return (
    <>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='isFurnished' label='Furnished' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasGarden' label='Garden' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasWater' label='Water' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasElectricity' label='Electricity' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasGarage' label='Garage' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasPool' label='Pool' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasSecurity' label='Security' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasInternet' label='Internet' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasGas' label='Gas' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasParking' label='Parking' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='studentsAllowed'
          label='students Allowed'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasGym' label='Gym' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='petsAllowed' label='pets Allowed' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasElevator' label='has Elevator' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasBalcony' label='has Balcony' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='builtForStudents'
          label='built For Students'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasFence' label='has Fence' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasCofO' label='has CofO' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='hasGovernorsConsent'
          label='has Governors Consent'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='hasRegisteredSurvey'
          label='has Registered Survey'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='hasApprovedBuildingPlan'
          label='has Approved Building Plan'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='hasDeedOfAssignment'
          label='has Deed Of Assignment'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item
          name='hasDeedOfConveyance'
          label='has Deed Of Conveyance'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col xs={{ span: 8 }} lg={{ span: 4 }}>
        <Form.Item name='hasExcision' label='has Excision' valuePropName='checked'>
          <Switch />
        </Form.Item>
      </Col>
    </>
  );
};

export default PropertyFeatures;
