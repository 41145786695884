import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default class DateFormatter {
  static format(date: Date | string): string {
    return dayjs(date).toLocaleString();
  }

  static getRelativeTime(date: Date | string): string {
    const now = dayjs();
    const dateObj = dayjs(date);
    const diff = now.diff(dateObj, 'day');
    if (diff < 1) {
      return dateObj.fromNow();
    }

    if (diff < 7) {
      return dateObj.format('dddd');
    }

    return dateObj.format('MMM D, YYYY');
  }
}
