import { PieChartOutlined } from '@ant-design/icons';
import { Property } from 'rentfree-api';
import './propertyFeatures.css';

interface Props {
  property: Property;
}

export default function PropertyFeatures({ property }: Props) {
  return (
    <>
      <h3>Property features</h3>
      <div className='offers__details-container'>
        <div className='offers-details'>
          {property.isFeatured ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property is Featured
            </div>
          ) : null}

          {property.hasGarden ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Garden
            </div>
          ) : null}

          {property.hasGarage ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Garage
            </div>
          ) : null}

          {property.hasPool ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has a Pool
            </div>
          ) : null}

          {property.hasSecurity ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Security
            </div>
          ) : null}

          {property.hasInternet ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Internet is available
            </div>
          ) : null}

          {property.hasElectricity ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Electricity is available
            </div>
          ) : null}

          {property.hasWater ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Water is available
            </div>
          ) : null}

          {property.hasGas ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Gas is available
            </div>
          ) : null}

          {property.hasParking ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Parking Space
            </div>
          ) : null}

          {property.hasGym ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Gym
            </div>
          ) : null}

          {property.studentsAllowed ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Students is Allowed
            </div>
          ) : null}

          {property.petsAllowed ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              pets is Allowed
            </div>
          ) : null}

          {property.hasElevator ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Elevator
            </div>
          ) : null}

          {property.hasBalcony ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Balcony
            </div>
          ) : null}

          {property.builtForStudents ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property is built For Students
            </div>
          ) : null}

          {property.hasFence ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Fence
            </div>
          ) : null}

          {property.hasCofO ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has CofO
            </div>
          ) : null}

          {property.hasGovernorsConsent ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Governors Consent
            </div>
          ) : null}

          {property.hasRegisteredSurvey ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Registered Survey
            </div>
          ) : null}

          {property.hasApprovedBuildingPlan ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Approved Building Plan
            </div>
          ) : null}

          {property.hasDeedOfAssignment ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Deed Of Assignment
            </div>
          ) : null}

          {property.hasDeedOfConveyance ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Deed Of Conveyance
            </div>
          ) : null}

          {property.hasExcision ? (
            <div className='offers-details_item'>
              <PieChartOutlined />
              Property has Excision
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
