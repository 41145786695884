import * as React from 'react';
import { Col, Row, Breadcrumb, Spin, Button, message } from 'antd';
import { ReactComponent as Like } from 'src/app/asset/icons/like.svg';
import { ReactComponent as Share } from 'src/app/asset/icons/share.svg';
import { ReactComponent as Logo } from 'src/app/asset/images/rentfree-logo.svg';
import { ReactComponent as Show } from 'src/app/asset/icons/show.svg';
import { LoadingOutlined } from '@ant-design/icons';
import BookNow from './components/book-now/BookNow';
import ReviewActivities from './components/review-activities/ReviewActivities';
import Sections from './components/sections/sections';
import PropertyFeatures from './components/property-features/PropertyFeatures';
import './propertyDetails.css';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectGetProperties } from '../property/store/propertySlice';
import { thunkGetProperty } from '../property/store/thunk';
import { APP_URL } from 'src/app/lib/variables';
import useModalState from 'src/app/hooks/useModalState';
import ImageGallery from './components/image-gallery/ImageGallery';

export default function PropertyDetails() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { property, loading } = useAppSelector(selectGetProperties);

  const { isOpen, closeModal, openModal } = useModalState();

  React.useEffect(() => {
    dispatch(thunkGetProperty(String(id)));
  }, [dispatch, id]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'check out this property',
          text: 'I found something amazing!',
          url: `${APP_URL}/property/${property?.id}`,
        });
      } catch (error) {
        message.error('Failed to share');
      }
    }
  };

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShare();
  };

  function Overlay() {
    return <div className='image-overlay' onClick={openModal}></div>;
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '100px',
            marginBottom: '100px',
            justifyContent: 'center',
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <div className='page-content home-screen-padding'>
          {property && (
            <div className='property-container page-content'>
              <Breadcrumb
                items={[
                  {
                    title: <a href='/'>Home</a>,
                  },
                  {
                    title: <a href='/property'>Property for rent</a>,
                  },
                  {
                    title: `${property.title}`,
                  },
                ]}
                style={{ margin: '44px 0px' }}
              />
              <div className='header-text'>
                <h3>{property.title}</h3>
                <div className='action'>
                  <Button type='text' className='sub-button' onClick={handleShareClick}>
                    <Share />
                    Share
                  </Button>
                  <Button type='text' className='sub-button' onClick={handleShareClick}>
                    <Like />
                    Like
                  </Button>
                </div>
              </div>

              {property.files && (
                <Row gutter={[4, 0]}>
                  <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                    <div className='main-image'>
                      <img
                        src={
                          property.files[0]
                            ? property.files[0].url
                            : 'https://place-hold.it/500x500'
                        }
                        alt='property details img'
                        className='property-image'
                      />
                      <Overlay />
                    </div>
                  </Col>

                  <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                    <Row gutter={[0, 4]}>
                      <Col span={24} className='sub-image'>
                        <img
                          src={
                            property.files[1]
                              ? property.files[1].url
                              : 'https://place-hold.it/500x500'
                          }
                          alt='property details img'
                          className='property-image'
                        />
                        <Overlay />
                      </Col>

                      <Col span={24} className='relative sub-image'>
                        <img
                          src={
                            property.files[2]
                              ? property.files[2].url
                              : 'https://place-hold.it/500x500'
                          }
                          alt='property details img'
                          className='property-image'
                        />

                        <Button
                          className='show-all-btn'
                          onClick={openModal}
                          icon={<Show width={16} height={16} />}
                        >
                          Show all photos
                        </Button>
                        <Overlay />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row className='details__page' gutter={{ xs: 30, lg: 60 }}>
                <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                  <div className='property__serviced'>
                    <div>
                      <span>
                        {property.bedrooms} {Number(property.bedrooms) > 1 ? "bedrooms" : "bedroom"} in ${property.lga}, ${property.state}
                      </span>
                    </div>
                    {/* <div className='badge__wrapper'>
                      <span className='badge'>
                        Verified <CheckOutlined />
                      </span>
                    </div> */}
                  </div>

                  <Sections>
                    <Logo />
                    <p style={{ marginTop: '12px' }}>
                      Every booking includes free protection from Host cancellations,
                      listing inaccuracies, and other issues like trouble checking in.
                    </p>
                    {/* <a>Learn more</a> */}
                  </Sections>
                  <Sections>
                    <div dangerouslySetInnerHTML={{ __html: property.description }} />
                  </Sections>
                  <Sections>
                    <PropertyFeatures property={property} />
                  </Sections>
                  <Sections>
                    <ReviewActivities />
                  </Sections>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }}>
                  <BookNow property={property} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className='property-search__location'>
                    <h3>Search location</h3>
                    <div className='main-image map-image'>
                      <img
                        src='https://place-hold.it/500x500'
                        alt='property details img'
                        className='property-image'
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}

      {property && (
        <ImageGallery files={property.files} open={isOpen} onClose={closeModal} />
      )}
    </>
  );
}
