import { Header } from 'antd/lib/layout/layout';
import './AppHeader.css';
import { useLocation } from 'react-router-dom';

function AppHeader({
  children,
  classNames,
}: {
  children: React.ReactNode;
  classNames?: string[];
}) {
  let { pathname } = useLocation();
  return (
    <Header
      className={['header', 'home-screen-padding', classNames && [...classNames]].join(
        ' '
      )}
    >
      <div className={`${pathname.includes('dashboard') ? '' : 'header-content'}`}>
        {children}
      </div>
    </Header>
  );
}

export default AppHeader;
