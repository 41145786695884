import { Affix, Breadcrumb, Button, Col, Input, Layout, Row } from 'antd';
import { AppFooter, AppHeader } from 'src/app/core/components';
import AdminSider from './components/AdminSider';
import AdminContent from './components/AdminContent';
import { useAppDispatch, useAppSelector } from 'src/app/core/store/hooks';
import { selectSidebarCollapsed, toggleSidebar } from './store/navigationSlice';
import AdminHeaderMenu from './components/AdminHeaderMenu';
import { CloseOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';

import './AdminDashboard.css';
import './Responsive.css';
import { NavLink, useLocation } from 'react-router-dom';
import { siteMap } from 'src/app/core/utils/helpers';

export default function AdminDashboard() {
  const sidebarCollapsed = useAppSelector(selectSidebarCollapsed);
  const dispatch = useAppDispatch();
  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');
  const pathnames = pathname.split('/');

  const pathLength = pathnames.length;

  return (
    <Layout className='layout-dashboard'>
      <AdminSider />
      <Layout className='content-layout'>
        <Affix offsetTop={0}>
          <AppHeader classNames={['admin-header ant-header-fixed']}>
            <Row gutter={[24, 0]} style={{ width: '100%' }}>
              <Col flex={'auto'}>
                <div className='ant-page-header-heading'>
                  <span
                    className='ant-page-header-heading-title'
                    style={{ textTransform: 'capitalize' }}
                  >
                    {pathname.replace('/', ' - ')}
                  </span>
                </div>
                <Breadcrumb>
                  {pathnames.map((pathname, index) => (
                    <Breadcrumb.Item key={index}>
                      {pathLength - 1 === index ? (
                        <>{pathname}</>
                      ) : (
                        <NavLink to={siteMap[pathname] as string}>{pathname}</NavLink>
                      )}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              </Col>
              <Col flex={'autto'} className='header-control'>
                <AdminHeaderMenu />
                <Button
                  onClick={() => {
                    dispatch(toggleSidebar(!sidebarCollapsed));
                  }}
                  icon={sidebarCollapsed ? <MenuOutlined /> : <CloseOutlined />}
                  className='sidebar-toggler'
                  type='link'
                ></Button>
                <Input
                  className='header-search'
                  placeholder='Type here...'
                  prefix={<SearchOutlined />}
                />
              </Col>
            </Row>
          </AppHeader>
        </Affix>
        <AdminContent />
        <AppFooter />
      </Layout>
    </Layout>
  );
}
