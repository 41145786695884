import { createAsyncThunk } from '@reduxjs/toolkit';
import { User, UserQuery } from 'rentfree-api';
import { client } from 'src/app/services/client';
import { Paginated } from '@feathersjs/feathers';

export const createUserThunk = createAsyncThunk(
  'users/createUser',
  async (user: User) => {
    return client.service('users').create(user);
  }
);

export const getUsersThunk = createAsyncThunk<Paginated<User>, UserQuery>(
  'users/getUsers',
  async (queries: UserQuery) => {
    return client.service('users').find({
      query: {
        $limit: 20,
        deletedAt: null,
        ...queries,
      },
    });
  }
);

export const getUserThunk = createAsyncThunk<User, string>(
  'users/getUser',
  async (id: string) => {
    return client.service('users').get(id);
  }
);

export const updateUserThunk = createAsyncThunk(
  'users/updateUser',
  async (payload: { id: string; data: Partial<User> }) => {
    const { id, data } = payload;
    return client.service('users').patch(id, data);
  }
);

export const deleteUserThunk = createAsyncThunk(
  'users/deleteUser',
  async (id: string) => {
    return client.service('users').remove(id);
  }
);
