import { Pagination } from 'antd';
import { Query } from '@feathersjs/feathers';

interface Props {
  limit: number;
  skip: number;
  total: number;
  showSizeChanger?: boolean;
  showTotal?: boolean;
  setQuery: (q: Query) => void;
}

export default function GenericPagination(props: Props) {
  const { limit, total, skip, setQuery } = props;

  const handlePageChange = (page: number, pageSize: number) => {
    const skip = (page - 1) * pageSize;
    setQuery({ $skip: skip, $limit: pageSize });
  };

  return (
    <Pagination
      style={{ marginBottom: '20px' }}
      showSizeChanger={props.showSizeChanger}
      onShowSizeChange={(current, size) => {
        setQuery({
          $skip: 0,
          $limit: size,
        });
      }}
      {...{
        showTotal: props.showTotal
          ? (total, range) => `${range[0]}-${range[1]} of ${total} items`
          : undefined,
      }}
      current={Math.floor(skip / limit) + 1}
      total={total}
      pageSize={limit}
      onChange={handlePageChange}
    />
  );
}
