import * as React from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Link, Outlet } from 'react-router-dom';
import { AppDrawer, AppHeader, AppLogo, RightMenu } from '../header';
import { AppFooter } from '../footer';

import './Main.css';
import { Avatar, Badge, Button, Divider, Dropdown, MenuProps, Space, theme } from 'antd';
import {
  ProfileOutlined,
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import useAuthUser from 'src/app/hooks/useAuthUser';
import { useAppDispatch } from '../../store/hooks';
import { thunkLogout } from 'src/app/features/auth/store/thunks';

const { useToken } = theme;

const items: MenuProps['items'] = [
  {
    label: <Link to={'/dashboard'}>Dashboard</Link>,
    key: 'dashboard',
    icon: <DashboardOutlined />,
  },
  {
    label: <Link to={'/dashboard/profile'}>Profile</Link>,
    key: 'profile',
    icon: <ProfileOutlined />,
  },
];

export function Main() {
  const { token } = useToken();
  const dispatch = useAppDispatch();
  const { user, authLoading } = useAuthUser();

  function handleLogout() {
    dispatch(thunkLogout());
  }

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  return (
    <Layout className='layout'>
      <AppHeader>
        <AppLogo />
        {!user && !authLoading ? (
          <>
            <RightMenu className='main-menu auth' />
            <AppDrawer />
          </>
        ) : (
          <Dropdown
            placement='bottomLeft'
            menu={{ items }}
            trigger={['click']}
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                <Divider style={{ margin: 0 }} />
                <Space style={{ padding: 8 }}>
                  <Button
                    type='text'
                    icon={<LogoutOutlined />}
                    danger
                    onClick={handleLogout}
                  >
                    Log Out
                  </Button>
                </Space>
              </div>
            )}
          >
            <Space size={24}>
              <Badge dot>
                <Avatar
                  style={{ cursor: 'pointer' }}
                  shape='square'
                  icon={<UserOutlined />}
                />
              </Badge>
            </Space>
            {/* </a> */}
          </Dropdown>
        )}
      </AppHeader>
      <Content className='site-content'>
        <Outlet />
      </Content>
      <AppFooter />
    </Layout>
  );
}
