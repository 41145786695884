import PropertyCard from './PropertyCard';
import NewsLetterForm from './NewsLetterForm';
import { Col, Row } from 'antd';
import { Breadcrumb } from 'antd';
import './PropertyList.css';
import { FeatureBox, FeatureBoxProps } from 'src/app/core/components';
import { getDetailedRelativeTime } from 'src/app/core/utils/helpers';
import { Property } from 'rentfree-api';
import { Paginated } from '@feathersjs/feathers';
import GenericPagination from 'src/app/core/components/GenericPagination';

export default function PropertyList({
  properties,
  updateQueryParams,
}: {
  properties: Paginated<Property>;
  updateQueryParams: any;
}) {
  const propertiesData = properties.data ?? [];
  const featured: FeatureBoxProps[] = [
    {
      location: 'Lagos',
      duration: '12 months',
      apartment_type: 'Flat',
      style: { backgroundImage: 'url(https://place-hold.it/300x500)' },
    },
    {
      location: 'Abuja',
      duration: '12 months',
      apartment_type: 'Flat',
      style: { backgroundImage: 'url(https://place-hold.it/300x500)' },
    },
    {
      location: 'Port Harcourt',
      duration: '12 months',
      apartment_type: 'Flat',
      style: { backgroundImage: 'url(https://place-hold.it/300x500)' },
    },
  ];

  return (
    <div className='apartment-wrapper page-content'>
      <Breadcrumb
        items={[
          {
            title: <a href='/'>Home</a>,
          },
          {
            title: 'Property for rent',
          },
        ]}
      />
      <p className='location'> Properties for rent (Ikeja, Lagos) </p>

      <Row className='main' gutter={[30, 30]}>
        <Col xl={16} sm={24}>
          <Row gutter={[10, 10]} className='featured-boxes'>
            {featured.map((feature, index) => (
              <Col sm={24} xs={24} lg={8} key={index}>
                <FeatureBox {...feature} />
              </Col>
            ))}
          </Row>

          <div className='cards'>
            {propertiesData.map((property, i) => (
              <PropertyCard
                key={i}
                title={property?.title ?? ''}
                description={property.description}
                files={property.files}
                price={property.price}
                location={property.address}
                bedrooms={property.bedrooms}
                bathrooms={property.bathrooms}
                url={`/property/${property.id}`}
                createdAt={getDetailedRelativeTime(property.createdAt!)}
              />
            ))}
            <div className='pagination'>
              <GenericPagination
                limit={properties.limit}
                total={properties.total}
                skip={properties.skip}
                setQuery={updateQueryParams}
              />
            </div>
          </div>
        </Col>
        <Col xl={8} sm={24} className='news-letter'>
          <NewsLetterForm />
        </Col>
      </Row>
    </div>
  );
}
