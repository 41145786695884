import { Col, Input, Form } from 'antd';
import { Property } from 'rentfree-api';

interface Props {
  property?: Property;
}

export default function LocationDetails(props: Props) {
  const { property } = props;

  return (
    <>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='address'
          label='Address'
          rules={[{ required: true, message: 'Please input your address' }]}
          initialValue={property ? property.address : ''}
        >
          <Input type='text' placeholder='Enter address' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          initialValue={property ? property.country : 'Nigeria'}
          name='country'
          label='Country'
          rules={[{ required: true, message: 'Please input your Country' }]}
        >
          <Input disabled type='text' placeholder='Enter country' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='state'
          label='State'
          rules={[{ required: true, message: 'Please input your State' }]}
          initialValue={property ? property.state : ''}
        >
          <Input type='text' placeholder='Enter state' />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='city'
          label='City'
          rules={[{ required: true, message: 'Please input your city' }]}
          initialValue={property ? property.city : ''}
        >
          <Input type='text' placeholder='Enter city' />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Form.Item
          name='lga'
          label='Local Government'
          rules={[{ required: true, message: 'Please input your lga' }]}
          initialValue={property ? property.lga : ''}
        >
          <Input type='text' placeholder='Enter lga' />
        </Form.Item>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='zipcode'
          label='zipcode'
          rules={[{ required: true, message: 'Please input your zipcode' }]}
          initialValue={property ? property.zipcode : ''}
        >
          <Input type='number' placeholder='Enter zipcode' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='latitude'
          label='Latitude'
          initialValue={property ? property.latitude : ''}
        >
          <Input type='text' placeholder='Enter latitude' />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Form.Item
          name='longitude'
          label='Longitude'
          initialValue={property ? property.longitude : ''}
        >
          <Input type='text' placeholder='Enter longitude' />
        </Form.Item>
      </Col>
    </>
  );
}
